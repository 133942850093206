import React, { Component } from "react";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";
import CommonDatePicker from "../../CommonDatePicker";
import moment from "moment";
import { getSchoolRankings } from "../../../apis";
import { replaceText } from "../../../resources/utils/stringUtils";
import { isDateValid, isNullOrEmpty } from "../../../resources/utils/validator";
import {
  getCurrentLang,
  getVaccineCalculationStatus,
} from "../../../resources/utils/commonUtils";
import "../../../resources/styles/elifeLeaderboard.scss";

const rankNames = [
  { tc: "第一名", en: "1st" },
  { tc: "第二名", en: "2nd" },
  { tc: "第三名", en: "3rd" },
];

class ElifeLeaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingData: true,
      rankings: [],
      currentType: "primary",
      currentRankings: [],
    };
    this.handleSwitchType = this.handleSwitchType.bind(this);
    this.hanldeUpdateCurrentRankings =
      this.hanldeUpdateCurrentRankings.bind(this);
  }
  componentDidMount() {
    getSchoolRankings()
      .then((res) => {
        if (res && res.data) {
          this.setState({
            rankings: res.data,
          });
        }
      })
      .finally(() => {
        this.setState({
          isLoadingData: false,
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.currentType !== prevState.currentType ||
      this.state.rankings !== prevState.rankings
    ) {
      this.hanldeUpdateCurrentRankings();
    }
  }

  hanldeUpdateCurrentRankings() {
    switch (this.state.currentType) {
      case "primary":
        this.setState({
          currentRankings: this.state.rankings.primarySchoolRankList,
        });
        break;
      case "secondary":
        this.setState({
          currentRankings: this.state.rankings.secondarySchoolRankList,
        });
        break;
      default:
        break;
    }
  }

  handleSwitchType(type) {
    this.setState({
      currentType: type,
    });
  }

  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={`page-elife-leaderboard page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="hero-banner">
          <img
            className="desktop-show"
            src={load(
              `./resources/images/elife/${locale}/life-leader-board-banner-desktop.jpg`
            )}
          />
          <img
            className="mobile-show"
            src={load(
              `./resources/images/elife/${locale}/life-leader-board-banner-mobile.jpg`
            )}
          />
        </div>

        <div className="container-fluid">
          <img
            className="deco deco-1"
            src={load(`./resources/images/elife-leaderboard/deco-cloud.png`)}
          />
          <img
            className="deco deco-2"
            src={load(`./resources/images/elife-leaderboard/deco-cloud.png`)}
          />

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="main-container">
                  <div className="tabs">
                    <div
                      className={`tab ${
                        this.state.currentType === "primary" ? "active" : ""
                      }`}
                      onClick={() => {
                        this.handleSwitchType("primary");
                      }}
                    >
                      {t("primarySchoolRankings")}
                    </div>
                    <div
                      className={`tab ${
                        this.state.currentType === "secondary" ? "active" : ""
                      }`}
                      onClick={() => {
                        this.handleSwitchType("secondary");
                      }}
                    >
                      {t("secondarySchoolRankings")}
                    </div>
                  </div>
                  <div className="update-time">
                    {t("dailyUpdateTime")}
                    <br />
                    {t("lastUpdate")}{" "}
                    {locale === "en"
                      ? moment(this.state.rankings?.lastUpdateTime).format(
                          "YYYY-MM-DD"
                        ) + " 9:00 a.m."
                      : moment(this.state.rankings?.lastUpdateTime).format(
                          "YYYY年MM月DD日"
                        ) + " 上午 9:00"}
                  </div>
                  <div className="only-sc only-tc-school-notice">
                    {t("Leaderboard - only TC School Name notice")}
                  </div>
                  <img
                    className="ranking-header"
                    src={load(
                      `./resources/images/elife-leaderboard/ranking-header.png`
                    )}
                  />
                  <div className="top-3">
                    {this.state.currentRankings?.map((ranking, index) => {
                      if (index < 3) {
                        const schoolName =
                          locale === "en" ? ranking.nameEn : ranking.nameTc;
                        const rankName =
                          locale === "en"
                            ? rankNames[index].en
                            : rankNames[index].tc;
                        return (
                          <div
                            className={`rank rank-${ranking.rank}`}
                            key={index}
                          >
                            <div className="inner">
                              <img
                                className="bg"
                                src={load(
                                  `./resources/images/elife-leaderboard/badge-${ranking.rank}.png`
                                )}
                              />
                              <div className="number">
                                <div className="text">{rankName}</div>
                                <div className="integer">{ranking.rank}</div>
                              </div>
                              <div className="name">{schoolName}</div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className="rankings">
                    {this.state.currentRankings?.map((ranking, index) => {
                      if (index >= 3) {
                        const schoolName =
                          locale === "en" ? ranking.nameEn : ranking.nameTc;
                        return (
                          <div
                            className={`rank rank-${ranking.rank}`}
                            key={index}
                          >
                            <div className="number">{ranking.rank}</div>
                            <div className="name">{schoolName}</div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="footer-image display-desktop"
            src={load(
              `./resources/images/elife-leaderboard/footer-desktop.png`
            )}
          />
          <img
            className="footer-image display-mobile"
            src={load(`./resources/images/elife-leaderboard/footer-mobile.png`)}
          />
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(ElifeLeaderboard);
