import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";

class LifeOverview extends Component {
  static defaultProps = {
    wcag22aURL: "https://www.w3.org/WAI/WCAG2AA-Conformance",
    appStoreDownloadURL: "https://apps.apple.com/us/app/e-life/id6502993463",
    googelPlayDownloadURL:
      "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life",
    huaweiDownloadURL: "https://appgallery.huawei.com/app/C110183237",
  };
  detectIOS() {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }

  detectAndroid() {
    return navigator.userAgent.match(/Android/i);
  }
  detectHuawei() {
    return navigator.userAgent.match(/huawei/i);
  }
  render() {
    let isIOS = this.detectIOS();
    let isAndroid = this.detectAndroid();
    let isHuawei = this.detectHuawei();
    let {
      languageCodeMap,
      wcag22aURL,
      appStoreDownloadURL,
      googelPlayDownloadURL,
      huaweiDownloadURL,
    } = this.props;
    const { locale, t } = this.props;

    if (locale === "en") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/us/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=en-US";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    } else if (locale === "zh-CN") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/cn/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=zh-CN";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    } else if (locale === "zh-HK") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/hk/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=zh-HK";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    }

    return (
      <div
        className={` page-container page-container-${locale} static-page-with-banner`}
        id="MainContent"
      >
        <div className="overview-hero-banner">
          <div className="container-full">
            <div className="row vertical-align">
              <img
                className="desktop-show-hide-tab"
                src={load(
                  `./resources/images/elife/${locale}/life-overview-banner-desktop.jpg`
                )}
              />
              <img
                className="tablet-show"
                src={load(
                  `./resources/images/elife/${locale}/life-overview-banner-mobile.jpg`
                )}
              />
            </div>
          </div>
          <div id="overview-download-btn" className="second-download-now">
            <div className="download-btn-row">
              <div className="download-overview-qr tablet-show">
                <h5>{t("Download Tutorial Content")}</h5>
                <img
                  className="tutorial-qr overview-qr "
                  src={load(
                    `./resources/images/elife/life-tutorial-download-qr.jpg`
                  )}
                />
              </div>
              <a
                id="btnDownloadAppStore"
                href={appStoreDownloadURL}
                rel="noopener noreferrer"
                className="second-download-now-href"
                target="_blank"
                tabIndex="0"
              >
                <img
                  id="imgBtnDownloadAppStore"
                  className="overview-download-button"
                  src={load(`./resources/images/${locale}/app_store.png`)}
                  alt={t("Download at App Store")}
                />
              </a>
              <a
                id="btnDownloadGooglePlay"
                className="overview-download-button"
                href={googelPlayDownloadURL}
                rel="noopener noreferrer"
                className="second-download-now-href"
                target="_blank"
                tabIndex="0"
              >
                <img
                  id="imgBtnDownloadGooglePlay"
                  className="overview-download-button"
                  src={load(`./resources/images/${locale}/google_play.png`)}
                  alt={t("Download at Google Play")}
                />
              </a>
              <a
                id="btnDownloadHuawei"
                href={huaweiDownloadURL}
                rel="noopener noreferrer"
                className="second-download-now-href"
                target="_blank"
                tabIndex="0"
              >
                <img
                  id="imgBtnDownloadHuawei"
                  className="overview-download-button"
                  src={load(`./resources/images/${locale}/huawei.png`)}
                  alt={t("Download at Huawei App Gallery")}
                />
              </a>
            </div>
          </div>
        </div>

        <div className="container ">
          <div className="row">
            <div className="col-lg-12 col-sm-12 overview-intro0">
              {/* <div class="release-date-reminder">
                {t("release date reminder")}
              </div> */}
              <img
                className=""
                src={load(`./resources/images/life-overview-intro0.png`)}
              />
              {t("e+ Life Overview intro Content")}
            </div>
          </div>
          <div className="container life-overview-3col">
            <div class="life-overview-3col-intro">
              {t("e+ Life Overview 3 items intro")}
            </div>
            <div className="overview-item col-lg-4 col-sm-12 ">
              <div class="overview-item-content">
                <img
                  className=""
                  src={load(`./resources/images/life-overview-intro1.png`)}
                />
                <div className="overview-item-text">
                  {t("e+ Life Point 1 Content")}
                </div>
              </div>
            </div>

            <div className="overview-item col-lg-4 col-sm-12 ">
              <div class="overview-item-content">
                <img
                  className=""
                  src={load(`./resources/images/life-overview-intro2.png`)}
                />
                <div className="overview-item-text">
                  {t("e+ Life Point 2 Content")}
                </div>
              </div>
            </div>

            <div className="overview-item col-lg-4 col-sm-12 ">
              <div class="overview-item-content">
                <img
                  className=""
                  src={load(`./resources/images/life-overview-intro3.png`)}
                />
                <div className="overview-item-text">
                  {t("e+ Life Point 3 Content")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="overview-park-container" className="container-fluid ">
          <div className="container">
            <h2 className="life-section-title">{t("e+ Go To Park")}</h2>

            <div className="row  vertical-align">
              <div className="col-md-4">
                <img
                  className="overview-park-main"
                  src={load(`./resources/images/life-overview-park-intro0.png`)}
                />
              </div>
              <div className="col-md-8 overview-park-intro">
                {t("e+ Go To Park Intro")}
              </div>
            </div>

            <div className="school-challenge elife-box-content">
              <div className="game-mode-title">{t("Game Mode Title")}</div>
              <div className="game-mode-sub-title">
                {t("Game Mode Sub Title")}
              </div>
              <div className="row life-challenge-3col">
                <div className="challenge-item col-lg-4 col-sm-12 ">
                  <img
                    className=""
                    src={load(
                      `./resources/images/elife/life-overview-park-intro1.png`
                    )}
                  />
                  <br />
                  <h4>{t("Game Mode 1 Title")}</h4>
                  {t("Game Mode 1 Content")}
                </div>

                <div className="challenge-item col-lg-4 col-sm-12 ">
                  <img
                    className=""
                    src={load(
                      `./resources/images/elife/life-overview-park-intro2.png`
                    )}
                  />
                  <br />
                  <h4>{t("Game Mode 2 Title")}</h4>
                  {t("Game Mode 2 Content")}
                </div>

                <div className="challenge-item col-lg-4 col-sm-12 ">
                  <img
                    className=""
                    src={load(
                      `./resources/images/elife/life-overview-park-intro3.png`
                    )}
                  />
                  <br />
                  <h4>{t("Game Mode 3 Title")}</h4>
                  {t("Game Mode 3 Content")}
                </div>
              </div>
            </div>

            <div className="overview-leaderboard-award-box school-challenge elife-box-content">
              <div className="game-mode-title">
                {t("Leaderboard and Award Title")}
              </div>
              <div className="game-mode-sub-title">
                {t("Leaderboard and Award Content 1")}
                <br />
                {t("Leaderboard and Award Content 2")}
                <br />
              </div>
              <a className="leaderboard-link" href="elife-leaderboard">
                {t("Go to Leader board")}
              </a>
            </div>
          </div>
        </div>

        <div className="container life-overview-button-container">
          <div className="container">
            <div className="life-overview-button col-lg-6 col-sm-12 ">
              <a href="elife-coins">
                <img
                  className="life-overview-button-img"
                  src={load(`./resources/images/life-overview-point-btn.png`)}
                />
                {t("e+Life Coins")}
                <img
                  className="life-overview-button-arrow"
                  src={load(`./resources/images/overview-button-arrow.png`)}
                />
              </a>
            </div>
            <div className="life-overview-button col-lg-6 col-sm-12 ">
              <a href="elife-tutorial">
                <img
                  className="life-overview-button-img"
                  src={load(
                    `./resources/images/life-overview-tutorial-btn.png`
                  )}
                />
                {t("e+Life Tutorial")}
                <img
                  className="life-overview-button-arrow"
                  src={load(`./resources/images/overview-button-arrow.png`)}
                />
              </a>
            </div>
            <div className="life-overview-button col-lg-6 col-sm-12 ">
              <a href="elife-faq">
                <img
                  className="life-overview-button-img"
                  src={load(`./resources/images/life-overview-faq-btn.png`)}
                />
                {t("e+Life faq")}
                <img
                  className="life-overview-button-arrow"
                  src={load(`./resources/images/overview-button-arrow.png`)}
                />
              </a>
            </div>
            <div className="life-overview-button col-lg-6 col-sm-12 ">
              <a href="elife-parklist">
                <img
                  className="life-overview-button-img"
                  src={load(
                    `./resources/images/life-overview-parklist-btn.png`
                  )}
                />
                {t("e+Life Park List")}
                <img
                  className="life-overview-button-arrow"
                  src={load(`./resources/images/overview-button-arrow.png`)}
                />
              </a>
            </div>
          </div>

          <div
            ref={this.youtubeContainer}
            className="second-youtube-container overview-video"
          >
            <iframe
              id="videoChildGrowth"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/FJaKLynj5ek"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(LifeOverview);
