import React, { Component } from "react";
import load from "../../ImageLoder";
import "../../resources/styles/bannerSlider.scss";

class BannerSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swipeX: null,
      currentSlide: 0,
      numberOfImage: 6,
      sliderInterval: null,
    };
  }

  componentDidMount = () => {
    this.initSlider();
  };

  componentWillUnmount() {
    clearInterval(this.state.sliderInterval);
  }

  initSlider = () => {
    const sliderTimerInSecond = 10;
    const interval = setInterval(this.nextSlide, sliderTimerInSecond * 1000);
    this.setState({
      sliderInterval: interval,
    });
  };

  nextSlide = () => {
    let { currentSlide, numberOfImage } = this.state;
    const that = this;
    if (currentSlide === numberOfImage - 1) {
      that.setState({ currentSlide: 0 });
    } else {
      that.setState({ currentSlide: currentSlide + 1 });
    }
    this.resetInterval();
  };

  prevSlide = () => {
    let { currentSlide, numberOfImage } = this.state;
    const that = this;
    if (currentSlide === 0) {
      that.setState({ currentSlide: numberOfImage - 1 });
    } else {
      that.setState({ currentSlide: currentSlide - 1 });
    }
    this.resetInterval();
  };

  resetInterval = () => {
    clearInterval(this.state.sliderInterval);
    this.initSlider();
  };

  getTouches = (e) => {
    return (
      e.touches || // browser API
      e.originalEvent.touches
    ); // jQuery
  };

  handleTouchStart = (e) => {
    const touch = this.getTouches(e)[0];
    this.setState({ swipeX: touch.clientX });
  };

  handleTouchMove = (e) => {
    if (!this.state.swipeX) {
      return;
    }
    let swipeXEnd = e.touches[0].clientX;
    let xDiff = this.state.swipeX - swipeXEnd;
    if (xDiff > 0) {
      /* left swipe */
      this.nextSlide();
    } else {
      /* right swipe */
      this.prevSlide();
    }
    this.setState({ swipeX: null });
  };

  render() {
    const { t, locale, languageCodeMap } = this.props;
    let ehealthSurveyURL;

    if (locale === "en") {
      ehealthSurveyURL = "https://www.surveymonkey.com/r/ND5S5GB";
    } else if (locale === "zh-CN") {
      ehealthSurveyURL = "https://www.surveymonkey.com/r/NDFKZN5";
    } else if (locale === "zh-HK") {
      ehealthSurveyURL = "https://www.surveymonkey.com/r/LS8TKTZ";
    }

    return (
      <div
        lang={locale}
        className="ehealth-website-banner"
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
      >
        <div
          id="banner_promulgation"
          className={
            this.state.currentSlide % this.state.numberOfImage === 5
              ? `fade-in`
              : `hide`
          }
        >
          <a
            href="/promulgation"
            className="homebanner-link"
            alt={t(
              "Brand new function of eHealth App is available now! Health Management helps you keep track of your blood pressure or blood sugar record with ease. Click here for more information."
            )}
            tabIndex="0"
          >
            <img
              className="display-desktop ehealth-website-image"
              src={load(
                `./resources/images/${locale}/landing-slider-img-1.jpg`
              )}
              alt={t(
                "Brand new function of eHealth App is available now! Health Management helps you keep track of your blood pressure or blood sugar record with ease. Click here for more information."
              )}
            />
            <img
              className="display-mobile ehealth-website-image"
              src={load(
                `./resources/images/${locale}/landing-slider-img-1-mobile.jpg`
              )}
              alt={t(
                "Brand new function of eHealth App is available now! Health Management helps you keep track of your blood pressure or blood sugar record with ease. Click here for more information."
              )}
            />
          </a>
        </div>
        <div
          id="banner_bpbg"
          className={
            this.state.currentSlide % this.state.numberOfImage === 4
              ? `fade-in`
              : `hide`
          }
        >
          <a
            href="/health-management"
            className="homebanner-link"
            alt={t(
              "Brand new function of eHealth App is available now! Health Management helps you keep track of your blood pressure or blood sugar record with ease. Click here for more information."
            )}
            tabIndex="0"
          >
            <img
              className="display-desktop ehealth-website-image"
              src={load(
                `./resources/images/${locale}/ehealth_bpbg_banner_desktop.jpg`
              )}
              alt={t(
                "Brand new function of eHealth App is available now! Health Management helps you keep track of your blood pressure or blood sugar record with ease. Click here for more information."
              )}
            />
            <img
              className="display-mobile ehealth-website-image"
              src={load(
                `./resources/images/${locale}/ehealth_bpbg_banner_mobile.jpg`
              )}
              alt={t(
                "Brand new function of eHealth App is available now! Health Management helps you keep track of your blood pressure or blood sugar record with ease. Click here for more information."
              )}
            />
          </a>
        </div>
        <div
          id="banner_adolescent"
          className={
            this.state.currentSlide % this.state.numberOfImage === 3
              ? `fade-in`
              : `hide`
          }
        >
          <a
            href="#"
            className="homebanner-link"
            alt={t(
              "Aged under 16 can register with eHealth via vaccination programme. Click here for more information"
            )}
            tabIndex="0"
          >
            <img
              className="display-desktop ehealth-website-image"
              src={load(
                `./resources/images/${locale}/ehealth_ehr_lite_banner_desktop.jpg`
              )}
              alt={t(
                "Aged under 16 can register with eHealth via vaccination programme. Click here for more information"
              )}
            />
            <img
              className="display-mobile ehealth-website-image"
              src={load(
                `./resources/images/${locale}/ehealth_ehr_lite_banner_mobile.jpg`
              )}
              alt={t(
                "Aged under 16 can register with eHealth via vaccination programme. Click here for more information"
              )}
            />
          </a>
        </div>
        <div
          id="banner_adolescent"
          className={
            this.state.currentSlide % this.state.numberOfImage === 2
              ? `fade-in`
              : `hide`
          }
        >
          <a
            href="/personal-folder"
            className="homebanner-link"
            alt={t(
              "Personal Folder - Deposit health records obtained outside Hong Kong"
            )}
            tabIndex="0"
          >
            <img
              className="display-desktop ehealth-website-image"
              src={load(
                `./resources/images/${locale}/ehealth_personal_folder_banner_desktop.jpg`
              )}
              alt={t(
                "Personal Folder - Deposit health records obtained outside Hong Kong"
              )}
            />
            <img
              className="display-mobile ehealth-website-image"
              src={load(
                `./resources/images/${locale}/ehealth_personal_folder_banner_mobile.jpg`
              )}
              alt={t(
                "Personal Folder - Deposit health records obtained outside Hong Kong"
              )}
            />
          </a>
        </div>
        <div
          id="banner_cbhr"
          className={
            this.state.currentSlide % this.state.numberOfImage === 1
              ? `fade-in`
              : `hide`
          }
        >
          <a
            href="/cross-boundary-health-record"
            className="homebanner-link"
            alt={t(
              "eHealth App New Pilot Function. Eligible Elderly Healthcare Voucher users can use the Health App to Apply Cross-boundaryHealth Record"
            )}
            tabIndex="0"
          >
            <img
              className="display-desktop ehealth-website-image"
              src={load(
                `./resources/images/${locale}/ehealth_cbhr_banner_desktop.jpg`
              )}
              alt={t(
                "eHealth App New Pilot Function. Eligible Elderly Healthcare Voucher users can use the Health App to Apply Cross-boundaryHealth Record"
              )}
            />
            <img
              className="display-mobile ehealth-website-image"
              src={load(
                `./resources/images/${locale}/ehealth_cbhr_banner_mobile.jpg`
              )}
              alt={t(
                "eHealth App New Pilot Function. Eligible Elderly Healthcare Voucher users can use the Health App to Apply Cross-boundaryHealth Record"
              )}
            />
          </a>
        </div>

        <div
          id="banner_promulgation"
          className={
            this.state.currentSlide % this.state.numberOfImage === 0
              ? `fade-in`
              : `hide`
          }
        >
          <a
            href="/elife-overview"
            className="homebanner-link"
            alt={t("elife landing banner alt")}
            tabIndex="0"
          >
            <img
              className="display-desktop ehealth-website-image"
              src={load(
                `./resources/images/${locale}/land-elife-banner-img.jpg`
              )}
              alt={t("elife landing banner alt")}
            />
            <img
              className="display-mobile ehealth-website-image"
              src={load(
                `./resources/images/${locale}/land-elife-banner-img-mobile.jpg`
              )}
              alt={t("elife landing banner alt")}
            />
          </a>
        </div>
        <div id="home_banner_nav">
          <img
            onKeyDown={(e) => e.keyCode === 13 && this.prevSlide()}
            onClick={this.prevSlide}
            id="home_slider_nav_left"
            src={load(`./resources/images/slider-left.png`)}
            alt={t("Previous")}
            tabIndex="0"
          ></img>
          <img
            onKeyDown={(e) => e.keyCode === 13 && this.nextSlide()}
            onClick={this.nextSlide}
            id="home_slider_nav_right"
            src={load(`./resources/images/slider-right.png`)}
            alt={t("Next")}
            tabIndex="0"
          ></img>
        </div>
      </div>
    );
  }
}

export default BannerSlider;
