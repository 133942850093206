import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class HealthProgramPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "program_slider_phone1.png",
      "program_slider_phone2.png",
      "program_slider_phone3.png",
    ];
    const sliderImageAlts = [
      "Screenshot of Health Programme",
      "Screenshot of Health Programme",
      "Screenshot of Health Programme",
    ];
    const sliderImageIds = [
      "img1HealthProgrammeSlider",
      "img2HealthProgrammeSlider",
      "img3HealthProgrammeSlider",
    ];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"health-program-bg.png"}
        sliderID="healthProgrammeSlider"
        sliderTitle={t("Health Programme Heading")}
        sliderTitleID={"textHealthProgrammeSliderTitle"}
        sliderText={t("Plan ahead wisely and effortlessly")}
        sliderTextID="textHealthProgrammeSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textHealthProgrammeSection0Title"
                  className="second-feature-title"
                >
                  {t("Get to know your health programmes in details")}
                </div>
                <div
                  id="textHealthProgrammeSection0Content"
                  className="second-feature-text"
                >
                  {t("View the health programmes you participate in easily")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1HealthProgrammeSection0"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="0"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/health-program_p0_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img1HealthProgrammeSection0"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="200"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/health-program_p0_2.png`
                  )}
                  alt=" "
                />
                <img
                  id="img1HealthProgrammeSection0"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="400"
                  className="absolute-image back"
                  src={load(`./resources/images/health-program_p0_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="health-program-cbhr"
          className="container-fluid second-feature-section last-section"
        >
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textHealthProgrammeSection1Title"
                  className="second-feature-title"
                >
                  {t(
                    "Facilitate your cross-border healthcare consultations Promote continuity of care"
                  )}
                </div>
                <div
                  id="textHealthProgrammeSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Eligible Elderly Healthcare Voucher users can now share the health records with designated healthcare institutions"
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1HealthProgrammeSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/health-program_cb_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2HealthProgrammeSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(`./resources/images/health-program_cb_2.png`)}
                  alt=" "
                />
                <img
                  id="img3HealthProgrammeSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/health-program_p1_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-down"
          data-aos-offset="-300"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoHealthProgramme"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/dwNohpI4eNM"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-left" className="col-md-6 col-sm-12">
                <div
                  id="textHealthProgrammeSection1Title"
                  className="second-feature-title"
                >
                  {t("Want to check on the balance of each health programme?")}
                </div>
                <div
                  id="textHealthProgrammeSection1Content"
                  className="second-feature-text"
                >
                  {t("Tap and view. It's that simple!")}
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1HealthProgrammeSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/health-program_p1_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2HealthProgrammeSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/health-program_p1_2.png`
                  )}
                  alt=" "
                />
                <img
                  id="img3HealthProgrammeSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/health-program_p1_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-right"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textHealthProgrammeSection2Title"
                  className="second-feature-title"
                >
                  {t("Look for your transaction history?")}
                </div>
                <div
                  id="textHealthProgrammeSection2Content"
                  className="second-feature-text"
                >
                  {t(
                    "Just one click - transaction date, amount and locations come into your view"
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1HealthProgrammeSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/health-program_p2_1.png`)}
                  alt=" "
                />
                <img
                  id="img2HealthProgrammeSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/health-program_p2_3.png`)}
                  alt=" "
                />
                <img
                  id="img3HealthProgrammeSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/health-program_p2_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-left" className="col-md-6 col-sm-12">
                <div
                  id="textHealthProgrammeSection3Title"
                  className="second-feature-title"
                >
                  {t("Your dedicated health assistant")}
                </div>
                <div
                  id="textHealthProgrammeSection3Content"
                  className="second-feature-text"
                >
                  {t(
                    "Always remind you of the latest information of the health programmes"
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1HealthProgrammeSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(
                    `./resources/images/${locale}/health-program_p3_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2HealthProgrammeSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/health-program_p3_2.png`)}
                  alt=" "
                />
                <img
                  id="img3HealthProgrammeSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/health-program_p3_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section last-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textHealthProgrammeSection2Title"
                  className="second-feature-title"
                >
                  {t("My membership card​")}
                </div>
                <div
                  id="textHealthProgrammeSection2Content"
                  className="second-feature-text"
                >
                  {t(
                    "Show my programme registration information on mobile device​"
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1HealthProgrammeSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/health-program_p4_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2HealthProgrammeSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/health-program_p4_2.png`)}
                  alt=" "
                />
                <img
                  id="img3HealthProgrammeSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image front"
                  src={load(
                    `./resources/images/${locale}/health-program_p4_3.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-down"
          data-aos-offset="-300"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoHealthProgramme"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/lybJKmHgsj0"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(HealthProgramPage);
